.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58);

  &_high {
    z-index: 9999999;
  }

  &_low {
    z-index: 888;
  }
}