@import "../../styles/vars";
@import "../../styles/mixins";

.main {
  body::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: orange;        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: blue;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid orange;  /* creates padding around scroll thumb */
  }
  background: radial-gradient(circle at -10% 50vh, rgba(88, 113, 242, 0.2) 2%, rgba(88, 113, 242, 0) 5%),
  radial-gradient(circle at 110% 50vh, rgba(88, 113, 242, 0.2) 2%, rgba(88, 113, 242, 0) 5%),
  radial-gradient(circle at 50% 100%, rgba(88, 113, 242, 0.2) 2%, rgba(88, 113, 242, 0) 10%);
  padding-bottom: 106px;

  @include lgScreenMedia {
    padding-bottom: 80px;
  }

  @include mdScreenMedia {
    padding-bottom: 60px;
  }

  @include smScreenMedia {
    padding-bottom: 50px;
  }
}
