@import "../../../styles/vars";
@import "../../../styles/mixins";


.mainRegisterPopup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  background: $color-blue-bold;
  border-radius: 19px;
  padding: 20px 20px 10px;
  width: calc(100% - 16px);
  max-width: 550px;
  @include flexCol();
  flex-direction: column;

  @include mdScreenMedia {
    padding: 20px 30px 30px;
  }

  @include smScreenMedia {
    padding: 15px;
  }


  &__text {
    @include setFont(16px,400);
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 20px;
    @include setFont(20px,700,$color-main);
  }

  &__btn {
    max-width: 200px;
  }
}
