@import "../../../styles/vars";
@import "../../../styles/mixins";


.subscribtionContainer {
  min-height: 100vh;
  padding-top: 35px;
  padding-bottom: 20px;

  &__title {
    margin-bottom: 35px;
  }
}
