@import "../../../styles/vars";
@import "../../../styles/mixins";


.arbitrageList {
  padding-top: 20px;

  &__container {
    padding: 40px;
    background-color: $color-black-bold;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 16px;
    gap: 25px;
    align-items: stretch;
    grid-template-columns: repeat(auto-fill,minmax(320px, 1fr));

    @include smScreenMedia {
      grid-template-columns: 100%;
      gap: 15px;
      padding: 0 0 20px;
      background-color: transparent;
    }
  }
}