// medias

$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 1024px;
$xl-screen: 1282px;

// colors
$color-bg: #0A0C1A;
$color-white: #fff;
$color-bg-transparent: rgba(0, 0, 0, 0.58);
$color-black: #070911;
$color-black-bold: #06070D;
$color-input-bg: #0A0D18;
$color-blue-bold: #0D1124;
$color-blue-bg: #080A16;
$color-blue-active: #2F3C99;
$color-blue-passive: #273166;
$color-main: #5871F2;
$color-red: #FF4040;
$color-red-bold: #2F0F1F;
$color-green: #144231;
$color-grey: #A5A5BB;
$color-border: #1F2336;
$color-text: #8F9CCB;
$slidebar-color: rgb(248, 36, 8);

$container-padding: 16px;

$font-fam-def: 'SuisseIntl', sans-serif;
$transition: all .5s