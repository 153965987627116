@import "vars";
@import "mixins";

:root {
  --body-width: 1024px;
}



@font-face {
  font-family: 'SuisseIntl';
  src: url('../assets/fonts/SuisseIntl-Regular.ttf') format('ttf');
  font-weight: 400
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('../assets/fonts/SuisseIntl-Medium.ttf') format('ttf');
  font-weight: 500;
}


*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:not(i) {
  font-family: $font-fam-def;
}

body {
  background: $color-bg;
  transition: $transition;
}

.navbarOpened {
  margin-left: 312px;

  @include mdScreenMedia {
    margin-left: 0;
  }
}

.container {
  width: 100%;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  padding-right: $container-padding;
  padding-left: $container-padding;

  @include xlScreenMedia {
    max-width: 960px;
  }

  @include lgScreenMedia {
    max-width: 720px;
  }

  @include mdScreenMedia {
    max-width: 560px;
  }
}

.titleTxt {
  @include setFont(45px, 600, $color-white);
  text-align: center;

  @include lgScreenMedia {
    font-size: 40px;
  }

  @include mdScreenMedia {
    font-size: 36px;
  }

  @include smScreenMedia {
    font-size: 25px;
  }
}

.subtitleTxt {
  @include setFont(13px, 600, #7288FF);
}

.contentTxt {
  @include setFont(16px, 400, $color-text);

  @include smScreenMedia {
    font-size: 15px;
  }
}

.blueText {
  color: $color-main;
}

.pageRouteTitle {
  @include setFont(13px, 400, #9EADFF);
}

.scrollbarDef {
  cursor: auto;

  &::-webkit-scrollbar {
    cursor: default;
    background: $color-grey;
    border-radius: 6px;
    background-clip: padding-box;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    cursor: default !important;
    width: 14px;
    border-radius: 6px;
    background-clip: padding-box;
    background: $color-main;
  }

}

.shadowedBox {
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.gradientBg {
  background: radial-gradient(circle at 50% 50%, rgba(88, 113, 242, 0.2) 2%, rgba(88, 113, 242, 0) 37%),
    radial-gradient(circle at 50% 0%, rgba(88, 113, 242, 0.2) 2%, rgba(88, 113, 242, 0) 37%);
  min-height: 100vh;

  @include smScreenMedia {
    background: none;
  }
}

.slidebar {
  background-color: $slidebar-color;
  scrollbar-color: red yellow;

}

.scrollbar {
  background-color: $slidebar-color;
  scrollbar-color: red yellow;

}

.scrollable-element {
  scrollbar-color: red yellow;
}

.mainInput {
  background-color: $color-input-bg;
  border-radius: 10px;
  padding: 16px;
  @include setFont(16px, 400);
  border: none;
  outline: none;
  transition: $transition;
  width: 100%;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 1px $color-main;
    background-color: #101320;
  }

  &::placeholder {
    color: #585859;
  }

  &_invalid {}

  &_withIcon {
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 17px 50%;
    padding-left: 50px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    background-image: inherit;
    -webkit-text-fill-color: $color-white;
    padding-left: 16px;
    -webkit-box-shadow: 0 0 0px 1000px #101320 inset;
    transition: $transition;
  }
}