@import "../../../styles/vars";
@import "../../../styles/mixins";

.mainBtn {
  width: 100%;
  cursor: pointer;
  border: none;
  background: $color-main;
  white-space: nowrap;
  text-align: center;
  padding: 15px 10px;
  border-radius: 10px;
  transition: $transition;
  @include setFont(17px,700,$color-white);
  text-overflow: ellipsis;
  overflow: hidden;

  @include smScreenMedia {
    font-size: 12px;
  }

  &:disabled {
    background: $color-grey;
    cursor: default;
  }

  &_passive {
    background-color: $color-blue-passive;
  }
}