@import "../../styles/vars";
@import "../../styles/mixins";

.error {
  width: 100%;
  height: 100vh;
  @include flexCol();
  padding: 0 16px;

  &__container {
    @include flexCol();
    gap: 10px;
    flex-direction: column;
  }

  &__img {
    max-width: 100%;
  }

  &__title {
    @include setFont(28px, 700, $color-main)
  }
}
